@import 'src/styles/config.scss';

.calendar_container {
  background-color: $white;
  border-radius: 10px;
}

.diary_container {
  width: 300px;
  height: 440px;
  border: 1px solid $grey;
  border-radius: 10px;
  padding: 15px;
}

.diary_header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  vertical-align: middle;
  margin-bottom: 15px;
}

.diary_header_topText {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
}

.diary_header_btn {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    background-image: url('./fi-rs-calendar.svg');
  }
}

.diary_header_topText_day {
  color: $black;
  margin: 5px;
}
.diary_header_topText_title {
  color: $primary;
  font-size: 16px;
  font-weight: 800;
  margin: 5px;
}

.hour_scroll_container {
  height: 336px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  @include scrollbars(10px, $grey, #f1f1f1);
}

.hour_container {
  height: 40px;
  width: 95%;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid $grey;
  border-radius: 2px;
  margin-bottom: 2px;
}
.hour_time {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  font-size: 12px;
  font-weight: 600;
  border-right: 1px solid $grey;
  height: 100%;
}
.hour_time_divider {
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.hour_text {
  flex: 1;
  height: 100%;
}
.hour_text_top {
  flex: 1;
  height: 50%;
  border-bottom: 1px solid $grey;
}
.hour_text_bottom {
  flex: 1;
  height: 50%;
}
