@import 'src/styles/config.scss';

.homeScreen_container {
  overflow: hidden;
  background-color: $grey;
  min-height: calc(100vh - 140px);
  position: relative;
}
.layout_container {
  background-color: $grey;
}

.layout_center_container {
  flex: 1;
}

.search_bar_container {
  width: 100%;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.section_container {
  width: 98%;
  margin: 0 auto 5px auto;
}

.info_container {
  width: 98%;
  background-color: $white;
  margin: 0 auto 5px auto;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}

.button_text {
  margin-bottom: 0;
}

.buttons_container {
  position: relative;
  left: 120px;
  top: 120px;
}

.animate_translate {
  transition: transform 250ms;
  &:hover {
    transform: translateX(-50px);
  }
}
