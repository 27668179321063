@import 'src/styles/config.scss';

.label,
.simpleField,
.textarea {
  display: block;
  width: 100%;
  color: $black;
  position: relative;
}

.label,
.optionLabel,
.errorMessage {
  font-size: 14px;
}

.optionLabel {
  margin-left: 8px;
  display: inline-block;
}

.checkMark {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 12px;
  height: 12px;
  background: url('./fi-rs-check.svg') no-repeat;
  background-size: 100%;
  display: inline;
}

.simpleField,
.textarea {
  font-size: 12px;
  margin: 4px 0;
  padding: 12px 35px 12px 15px;
  border: none;
  outline: none;
  background-color: $grey;
  border-radius: 24px;
  text-overflow: ellipsis;
}
.simpleField {
  height: 40px;
}

.disabledField {
  font-size: 12px;
  margin: 4px 0;
  padding: 12px 35px 12px 15px;
  border: none;
  outline: none;
  background-color: #8d8d8d;
  border-radius: 24px;
}

.dateInput {
  border: none;
  outline: none;
  background-color: $grey;
}

.dateInputWrapper {
  width: 100%;
}
.switchLabel {
  font-size: 12px;
}

.textarea {
  min-height: 80px;
}

.errorMessage {
  color: $red;
  margin: 0;
  font-size: 10px;
  padding-left: 5px;
}

p.label {
  margin: 15px 0;
}

.inputRadio,
.inputCheckbox,
.inputSwitch {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: $grey;
  border: 1px solid $primary;
  border-radius: 50%;
  cursor: pointer;
}
.checkboxLabel {
  line-height: 1.6;
  cursor: pointer;
}

.inputSwitch {
  margin: 5px;
}

.optionContainer {
  display: flex;
  align-items: center;
  line-height: 1.6;
  position: relative;
}

.inputCheckbox {
  border-radius: 4px;
  margin: 0 5px 0 0;
}

.inputCheckbox:checked,
.inputRadio:checked {
  background-color: $primary;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  border: 1px solid $primary;
  appearance: none;
}
.inputRadio:checked {
  background-image: url('./fi-rs-check-white.svg');
}

.checkbox_icon {
  position: absolute;
  width: 12px;
  height: 12px;
  padding-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
    fill: $white;
  }
}

.inputSwitch {
  border-radius: 12px;
  width: 30px;
  height: 16px;
  border: 2px solid $grey;
  background-color: $grey;
  color: $grey;
  display: inline-flex;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $white;
    transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
  }
}

.inputSwitch:checked {
  background-color: $primary;
  border-color: $primary;
  color: $primary;

  &::before {
    right: 12px;
    transform: translateX(100%);
  }
}

.switchStatus {
  font-size: 12px;
  margin-left: 8px;
}

.icon_container {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
    height: 100%;
    fill: $darkGrey;
  }
}
.iconError {
  width: 75%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
    height: 100%;
  }
}
.svg_check {
  fill: $primary;
}
.svg_cross {
  fill: $red;
}

.icon_container_left {
  z-index: 10;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon_left {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100%;
    height: 100%;
    fill: $darkGrey;
  }
}

.morePaddingLeft {
  padding-left: 30px;
}

.morePaddingRight {
  padding-right: 40px;
}

.extendedTagInput_no_Suggestions {
  background-color: $grey;
  border-radius: 24px;
  position: relative;
}
.extendedTagInput_Suggestions {
  background-color: $grey;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  position: relative;
}

.suggestionsList {
  position: absolute;
  z-index: 10;
  background-color: $grey;
  overflow: hidden;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  width: 100%;
}
.suggestionsList_item {
  font-size: 14px;
  padding: 10px 10px 15px 15px;
  &:hover {
    width: 100%;
    background-color: $primary;
    color: $white;
  }
}

.suggestionActive {
  background-color: $primary;
  color: $white;
}

.selectBox {
  & option:hover {
    box-shadow: 0 0 10px 100px red inset;
  }
}
.option {
  position: absolute;
  background-color: $grey;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  .option:hover {
    box-shadow: 0 0 10px 100px red inset;
  }
}

.selectList {
  position: absolute;
  left: 0;
  top: 39px;
  background-color: $grey;
  width: 100%;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  z-index: 999;
  overflow: hidden;
  max-height: 200px;
  overflow-y: scroll;
  li {
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
  }
}

.selectList::-webkit-scrollbar {
  width: 10px;
}
.selectList::-webkit-scrollbar-track {
  display: none;
}
.selectList::-webkit-scrollbar-button {
  height: 20px;
}
.selectList::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}

.selectList_borders {
  border-radius: 24px;
}
.selectIcon {
  height: 50%;
  width: 50;
}

.tagInput {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  &::placeholder {
    color: inherit;
  }
  &::-ms-input-placeholder {
    color: inherit;
  }
  &::-ms-input-placeholder {
    color: inherit;
  }

  &:focus {
    outline: none;
  }
}

.icon_container_gps {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}

.primary {
  background-color: $primary;
  color: $white;
  i {
    svg {
      fill: $white;
    }
  }
  .selectList {
    background-color: $primary;
    padding: 0 10px 10px 10px;

    li {
      &:hover {
        background-color: #2d4171;
        border-radius: 24px;
      }
    }
  }
}

.secondary {
  background-color: $primary;
  color: $white;
  i {
    svg {
      fill: $white;
    }
  }
  .selectList {
    background-color: $primary;
    padding: 0 10px 10px 10px;
    li {
      &:hover {
        background-color: #5682af;
        border-radius: 24px;
      }
    }
  }
}
.grey {
  background-color: $grey;
  color: $black;
  i {
    svg {
      fill: $black;
    }
  }
  .selectList {
    background-color: $grey;
    padding: 0 10px 10px 10px;
    li {
      &:hover {
        background-color: $white;
        border-radius: 24px;
        color: $black;
      }
    }
  }
}
.outline {
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-color: $primary;
  border-style: solid;
  background-color: $white;
  color: $primary;
  i {
    svg {
      fill: $primary;
    }
  }
  .selectList {
    width: calc(100% + 2px);
    left: -1px;
    box-sizing: border-box;
    background-color: $white;
    padding: 0 10px 10px 10px;
    border-top: 0;
    border-left: 1px;
    border-right: 1px;
    border-bottom: 1px;
    border-color: $primary;
    border-style: solid;
  }
  .selectList::-webkit-scrollbar-thumb {
    background-color: $primary;
  }

  li {
    &:hover {
      background-color: #e1e6f2;
      border-radius: 24px;
      color: $primary;
    }
  }
}

.purple {
  .selectList {
    background-color: $grey;
    padding: 0 10px 10px 10px;
    li {
      font-weight: 600;
      &:hover {
        color: $primary;
      }
    }
  }
}

.disabled {
  background-color: $grey;
  color: $black;
}

.textInput_transparent {
  border-radius: 6px;
  border: 1px solid $white;
  background-color: transparent;
  color: $white;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $white;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $white;
  }
}

.select_text {
  margin: 0;
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
