@import 'src/styles/config.scss';

.btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 100%;
  padding: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: -9px 2px 9px -3px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: -9px 2px 9px -3px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: -9px 2px 9px -3px rgba(0, 0, 0, 0.31);
  i {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.rotate {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate.down {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.primary {
  @include primary;
  svg {
    width: 100%;
    height: 100%;
    fill: $white;
  }
}

.secondary {
  @include disabled;
  svg {
    width: 100%;
    height: 100%;
    fill: rgba(48, 48, 48, 0.5);
  }
}

.purple {
  @include purple;
  svg {
    width: 100%;
    height: 100%;
    fill: $grey;
  }
}

.light_purple {
  @include light_purple;
  svg {
    width: 100%;
    height: 100%;
    fill: $grey;
  }
}

.white {
  @include white;
  svg {
    width: 100%;
    height: 100%;
    fill: $primary;
  }
}
