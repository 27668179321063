@import 'src/styles/config.scss';

.mini_map_container {
  width: 100%;
  height: 200px;
  position: relative;

  .map_wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .map_confirm_container {
    display: none;
  }
  .edit_map_container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(56, 82, 167, 0.5);
    border-radius: 24px;
  }
}

.map_fullScreen_container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  .map_wrapper {
    width: 80vw;
    height: 80vh;
  }
}

@media (max-width: 767px) {
  .map_fullScreen_container {
    background-color: $grey;
    .map_wrapper {
      width: 100vw;
      height: 100vh;
    }
    .map_confirm_container {
      display: flex;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      background-color: $primary;
    }
  }
}
