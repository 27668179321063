.blurhash_wrapper {
  margin: 0 auto;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
}
