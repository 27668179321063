@import 'src/styles/config.scss';

.dashboardClinicsScreen_container {
  background-color: $grey;
  min-height: calc(100vh - 140px);
}
.day_shifts_container {
  width: 98%;
  margin: 0 auto 5px auto;
}
.table_container {
  background-color: $white;
  width: 98%;
  margin: 10px auto;
  border-radius: 13px;
  padding: 10px;
  text-transform: uppercase;
}
.title {
  color: #499cdb;
  font-size: calc(100vw * 0.03);
  font-weight: 500;
  text-transform: uppercase;
}

.actions_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.table_container {
  width: 98%;
  margin: 10px auto;
  background-color: $white;
  border-radius: 13px;
  letter-spacing: 1.1px;
  padding: 15px;
  .table {
    margin: 0 auto;
  }
  .table_head {
    border-bottom: 2px $primary solid;
  }
  .table_head_th {
    text-align: center;
    color: #000000;
    font-size: 14px;
    padding: 5px;
  }
}
.image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
