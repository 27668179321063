/* Colors */
$white: #ffffff;
$white2: #f8f6f6;
$grey: #e2e7e5;
$black: #2d2d2d;
$violet: #5867dd;
$green: #c4ca6a;
$dark-green: #74b289;
$red: #b25357;
$orange: #e39d45;
$bg-white: #fafafa;
$darkGrey: #676767;
$darkGrey2: #6b6b6b;

$danger: #e15a54;
$warning: #e7ba5c;
$primary: #1cac81;
$info: #5578eb;
$success: #a1c4a2;
$message: #645ca1;
$medic: #9816f4;
$patient: #5578eb;
$clinic: #1cac81;

/* Fonts */
$main-font: 'Montserrat', sans-serif;
$font_xs: 10px;

/* Breakpoints */
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1536px;

/* Img directory */

$img-directory: '../../../public/';
