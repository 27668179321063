@import 'config/variables';
@import 'config/mixins';
@import 'fonts';
@import 'normalize';
@import "~@flaticon/flaticon-uicons/css/all/all";

$theme-colors: (
  'primary': $primary,
  'white': $white,
  'customGrey': $grey,
  'dark': $black,
);
@import '../../node_modules/bootstrap/scss/bootstrap';

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: $main-font;
  background-color: $bg-white;
  color: $black;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

img {
  max-width: 100%;
}

button {
  border: none;
  outline: none;
  background: none;
  font-family: $main-font;
}

@mixin primary {
  background-color: $primary;
  color: $white;
}

@mixin secondary {
  background-color: $primary;
  color: $white;
}

@mixin outline {
  border: 2px solid $primary;
  background-color: $white;
  color: $primary;
}

@mixin disabled {
  background-color: $grey;
  color: $black;
}

@mixin light {
  background-color: $white;
  color: $black;
}

@mixin dark {
  background-color: $black;
  color: $white;
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 20px;
  }

  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
