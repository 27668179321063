@import 'src/styles/config.scss';

.container {
  width: 100%;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  padding: 10px;
}

.draft_container {
  flex-grow: 1;
  height: 100%;
  text-align: center;
}
