@import 'src/styles/config.scss';

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 100%;
  padding: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.no_image {
  display: none;
}

@keyframes skeleton {
  0% {
    opacity: 0.5;
  }

  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.5;
  }
}
.loading {
  background-color: $grey;
  animation: skeleton 2s infinite;
}

.primary {
  @include primary;
  svg {
    width: 100%;
    height: 100%;
    fill: $white;
  }
}

.secondary {
  @include secondary;
  svg {
    width: 100%;
    height: 100%;
    fill: $white;
  }
}
.disabled {
  @include disabled;
  svg {
    width: 100%;
    height: 100%;
    fill: $white;
  }
}

.grey {
  @include disabled;
  svg {
    width: 100%;
    height: 100%;
    fill: $primary;
  }
}
