@import 'src/styles/config.scss';

.clinic {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  margin-top: 10px;
}

.mobile_clinic {
  background-color: $white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 20px;
}

.clinics_select_container {
  flex: 1;
  max-width: 400px;
}

.clinics_clinic_text {
  color: $primary;
  margin: 0;
}

@media (min-width: 768px) {
  .clinic {
    background-color: $primary;
    color: #fefefe;
    font-weight: 700;
    font-size: 14px;
    height: 80px;
    flex: 2;
  }
  .clinics_clinic_text {
    color: $white;
  }
}
