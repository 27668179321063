@import 'src/styles/config.scss';

.dashboardClinicsScreen_container {
  background-color: $grey;
  min-height: calc(100vh - 140px);
  position: relative;
  overflow-x: hidden;
}
.layout_container {
  background-color: $grey;
}
.clinics_container {
  width: 98%;
  margin: 0 auto 5px auto;
}
.table_container {
  background-color: $white;
  width: 98%;
  margin: 10px auto;
  border-radius: 13px;
  padding: 10px;
  text-transform: uppercase;
}
.title {
  color: #499cdb;
  font-size: calc(100vw * 0.03);
  font-weight: 500;
  text-transform: uppercase;
}

.actions_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.button_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 150px;
  span {
    margin-left: 5px;
  }
}
.clinicList_item_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_text {
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    margin: 0;
  }
  .title {
    text-transform: uppercase;
    color: $primary;
    font-weight: 700;
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) {
  .icon_text {
    justify-content: flex-start;
  }
  .clinicList_item_container {
    flex-direction: row;
  }
}
