@import 'src/styles/config.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  min-width: 100px;
  width: 80%;
  border: 1px solid $grey;
  border-radius: 50px;
  background-color: $white;
  position: relative;

  .text__input {
    border: none;
    background: transparent;
    outline: none;
    width: 80%;
  }
  .text__input__small {
    font-size: 12px;
  }

  input::placeholder {
    font-size: 12px;
  }
}
.mobile__container {
  background-color: $grey;
  .mobile__text__input {
    background-color: $grey;
  }
}

.small {
  height: 40px;
}
.medium {
  height: 50px;
}

.disabled {
  background-color: rgba(232, 232, 232, 0.4);
}

.button_container {
  height: 100%;
  width: 80px;
  min-width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    width: 100%;
    height: calc(100% + 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .btn__icon {
      width: 20px;
      height: 20px;
    }
    .btn__icon__small {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn__icon__disabled {
      svg {
        width: 100%;
        height: 100%;
        fill: #878586;
      }
    }
    .btn__icon__enabled {
      svg {
        width: 100%;
        height: 100%;
        fill: $primary;
      }
    }
  }
  .btn__disabled {
    cursor: default;
  }
}

@media (min-width: 768px) {
  .container {
    input::placeholder {
      font-size: 14px;
    }
  }
}
