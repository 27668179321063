@import 'src/styles/config.scss';

.container {
  color: $white;
  border-radius: 24px;
  max-height: 600px;
  overflow-y: auto;
  flex-grow: 1;
  margin: 0 1px;
  &::-webkit-scrollbar {
    width: 1px;
  }
}
.header_minimized {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}
.header_maximized {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content_container {
  padding: 20px;
}
.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  margin: 0;
  font-size: 0.8rem;
}

.title_actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form_title {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  .title {
    font-weight: 700;
    font-size: 0.7rem;
  }
}

.line {
  width: 90%;
  margin: 20px auto;
  height: 1px;
  background-color: #ffffff;
}

.shift_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shift_date_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .shift_date_text {
    margin: 0;
    font-size: 0.8rem;
  }
}

.shift_date_actions {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .shift_date_text {
    margin: 0;
    font-size: 0.8rem;
  }
}

.shift_place_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .shift_date_text {
    margin: 0;
    font-size: 0.8rem;
  }
}

.action_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
}
