@import 'src/styles/config.scss';

.icon_container {
  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
}
