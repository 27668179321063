@import 'src/styles/config.scss';

.container {
  margin: 4px 0;
  height: 40px;
  font-family: inherit;
  & :focus {
    box-shadow: none !important;
  }
}

.input {
  font-size: 12px !important;
  margin: 4px 0;
  padding: 12px 35px 12px 15px;
  border: none !important;
  outline: none;
  background-color: $grey;
  border-radius: 24px;
  text-overflow: ellipsis;
  max-width: 100%;
  height: 100% !important;
  background-color: #e2e7e5 !important;
}
.button {
  background-color: #e2e7e5 !important;
  border: none !important;
  border-radius: 24px !important;

  & :hover {
    background-color: #e2e7e5 !important;
    border: none !important;
    border-radius: 24px !important;
  }
  & :focus {
    background-color: #e2e7e5 !important;
    border: none !important;
    border-radius: 24px !important;
  }
}
.dropdown {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #e2e7e5 !important;
  &::-webkit-scrollbar {
    display: none !important;
  }

  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  & :hover {
    background-color: #e2e7e5 !important;
  }
}

.errorMessage {
  color: $red;
  margin: 0;
  font-size: 10px;
  padding-left: 5px;
}

.label {
  display: block;
  width: 100%;
  color: $black;
  position: relative;
  font-size: 14px;
}
