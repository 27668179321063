@import '../../styles/config.scss';

.iconRoundedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  border-radius: 50%;
  color: #ffffff;
  width: 40px;
  height: 40px;
}

.lineContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .line {
    height: 90%;
    width: 3px;
    background-color: #ebedf2;
  }
}

.medicalDataContainer {
  padding-left: 40px;
}

.medicalData {
  font-size: 1rem;
}
