@import 'src/styles/config.scss';
.map_container {
  width: 100%;
  height: 80%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}

.static_map {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  cursor: pointer;
}
.address_text {
  padding-top: 10px;
  margin: 0;
  text-align: center;
  font-weight: 600;
  color: $primary;
}

.info_box_container {
  position: absolute;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.back_container {
  position: absolute;
  padding: 20px;
}
.info_box_desktop {
  flex-direction: row;
}
.info_box_mobile {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.icon_container {
  width: 30px;
  height: 30px;
  .icon {
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: 100%;
      fill: $primary;
    }
  }
}

.text_container {
  padding-left: 20px;
}
.text_title,
.text_subTitle {
  margin: 0;
}

.text_title {
  color: $primary;
  font-weight: 700;
  text-transform: uppercase;
}
.text_subTitle {
  color: #959292;
  font-weight: 700;
  span {
    text-transform: uppercase;
  }
}

.map_confirm_container,
.map_confirm_container_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}
.map_confirm_container {
  background-color: $white;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  height: 20%;
}
.map_confirm_container_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  height: 35%;
}

@media (max-width: 767px) {
  .map_container {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    height: 65%;
  }
  .text_title {
    font-size: 12px;
    color: $primary;
  }
  .text_subTitle {
    font-size: 12px;
    color: $white;
  }
  .info_box_container {
    position: relative;
    width: 90%;
    justify-content: center;
    padding: 0;
  }
  .icon_container {
    align-self: flex-start;
    margin-left: 20px;
    margin-bottom: 10px;
    .icon {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
        fill: $white;
      }
    }
  }
}
