@import 'src/styles/config.scss';

.heading {
  background-color: $primary;
}
.logo {
  height: 70%;
  width: 130px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    fill: $white2;
  }
}
@media (min-width: 576px) {
  .heading {
    background-color: $bg-white;
    .text {
      color: $black;
    }
  }
}
