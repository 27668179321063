@import 'src/styles/config.scss';

.container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: $primary;
  border-radius: 24px;
  padding: 10px;
  top: 0;
  right: 0;
  z-index: 10;
}

.menu_list {
  width: 100%;
  margin: 0;
  padding: 10px;
}

.menu_item {
  color: $white;
  text-transform: uppercase;
  margin: 5px 0;
  padding: 10px;
  background-color: $primary;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 1.1px;
  cursor: pointer;
}

.menu_item_link {
  width: 100%;
  height: 100%;
  & :hover {
    color: $white;
  }
}

@media (min-width: 768px) {
  .container {
    height: fit-content;
    border-radius: 0px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    top: 70px;
    right: 0;
  }
}
