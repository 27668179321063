@import 'src/styles/config.scss';

.container {
  // border: 1px solid rgba(193, 192, 192, 1);
  width: 98%;
  background-color: $white;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  letter-spacing: 1.1px;
  padding: 15px;
}

.info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content_container {
  width: 100%;
  padding: 10px;
}

.end_line_container {
  padding: 0 10px 10px 10px;
  width: 100%;
}

.end_line {
  height: 8px;
  width: 20%;
  background-color: $grey;
  border-radius: 24px;
  margin: 0 auto;
}

.day {
  color: $primary;
  font-size: calc(100vw * 0.03);
  font-weight: 700;
  text-transform: uppercase;
}
.mobile_day {
  color: $primary;
  font-size: calc(100vw * 0.03);
  font-weight: 500;
  text-transform: uppercase;
}

.resume {
  color: $primary;
  font-size: calc(100vw * 0.03);
  font-weight: 500;
  text-transform: uppercase;
}

.text_title {
  text-transform: none;
}

@media (min-width: 768px) {
  .container {
    width: 100%;
    border-radius: 0px;
    justify-content: space-between;
  }
  .day {
    font-size: 1.2rem;
  }
  .resume {
    font-size: 1rem;
  }
}
