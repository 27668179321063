@import 'src/styles/config.scss';

.modal_fullscreen_container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(38, 166, 127, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_container {
  max-width: 1200px;
  position: relative;
  border-radius: 24px;
  padding: 24px 16px 16px;
  background-color: #ffffff;
}

.close_button {
  position: absolute;
  right: 4px;
  top: 4px;
}

.modal_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
}

.title_container {
  color: $primary;
}

.title_container_padding {
  padding: 0 1.5rem;
}

.title,
.subTitle {
  text-transform: uppercase;
  text-align: center;
}

.title {
  letter-spacing: 1px;
  font-weight: 700;
}

.subTitle {
  font-weight: 600;
  max-width: 500px;
}
