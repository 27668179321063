@import 'src/styles/config.scss';

.table_container {
  width: 100%;
}
.table_textField {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}
.actions_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
