@import 'src/styles/config.scss';

.fullScreenLoader_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#132344, $primary);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo_container {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    fill: #f8f6f6;
  }
}

.logo_text {
  position: absolute;
  bottom: 0;
  color: #fafafa;
  max-width: 500px;
  padding: 20px;
  text-align: center;
}
