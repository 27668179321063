.cardContainer {
  color: #ffffff;
  border-radius: 24px;
  margin: 10px;
  overflow: hidden;
  font-size: 14px;
  .cardHeader {
    padding: 20px;
    display: flex;
    align-items: center;
    .cardHeaderTitle {
      padding-left: 10px;
    }
  }
  .cardMainContent {
    text-align: center;
    .mainIconContainer {
      padding: 10px;
    }
    .cardMainContentText {
      padding: 0 20px 5px 20px;
    }
  }
  .cardFooter {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    text-align: end;
    padding: 20px;
  }
}
