/* Media queries */
@mixin media-md {
  @media screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin media-lg {
  @media screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin media-xl {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

/* Buttons & Dropdowns */
@mixin primary {
  background-color: $primary;
  color: $white;
}

@mixin secondary {
  background-color: $primary;
  color: $white;
}

@mixin outline {
  border: 2px solid $primary;
  background-color: $white;
  color: $primary;
}
@mixin outline-secondary {
  border: 2px solid $medic;
  background-color: $white;
  color: $medic;
}
@mixin outline-white {
  border: 2px solid #fefefe;
  background-color: transparent;
  color: #fefefe;
}

@mixin disabled {
  background-color: $grey;
  color: $black;
}

@mixin light {
  background-color: $white;
  color: $black;
}

@mixin dark {
  background-color: $black;
  color: $white;
}

@mixin purple {
  background-color: $primary;
  color: $grey;
}

@mixin marineBlue {
  background-color: $primary;
  color: $white;
}

@mixin light_purple {
  background-color: $primary;
  color: $white;
}

@mixin light_blue {
  background-color: $primary;
  color: $white;
}

@mixin white {
  background-color: $white;
  color: $primary;
}

@mixin medic {
  background-color: $medic;
  color: $white;
}

@mixin patient {
  background-color: $patient;
  color: $white;
}

@mixin clinic {
  background-color: $clinic;
  color: $white;
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 20px;
  }

  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
