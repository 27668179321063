@import 'src/styles/config.scss';

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 24px;
  height: 40px;
  min-width: 100px;
  padding: 8px 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
}

.primary {
  @include primary;
}

.secondary {
  @include secondary;
}

.outline {
  @include outline;
}
.outline-secondary {
  @include outline-secondary;
}
.outline-white {
  @include outline-white;
}

.disabled {
  @include disabled;
}

.light {
  @include light;
}
.medic {
  @include medic;
}
.patient {
  @include patient;
}
.clinic {
  @include clinic;
}

.with-icon {
  justify-content: flex-start;

  &::before {
    content: '';
    background-repeat: no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
}

.with-icon-after {
  justify-content: flex-start;

  &::after {
    content: '';
    background-repeat: no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
}

.special-violet-btn {
  @extend .with-icon;
  background-color: $violet;
  color: $white;

  &::before {
    background-image: url('./fi-rs-plus-small.svg');
  }
}

.special-blue-btn {
  @extend .with-icon;
  background-color: $primary;
  color: $white;

  &::before {
    background-image: url('./fi-rs-address-book.svg');
  }
}

.special-green-btn {
  @extend .with-icon;
  background-color: $green;
  color: $white;

  &::before {
    background-image: url('./fi-rs-document.svg');
  }
}

.btn_shadow {
  box-shadow: -9px 9px 9px -4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -9px 9px 9px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -9px 9px 9px -4px rgba(0, 0, 0, 0.2);
}
