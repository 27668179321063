@import 'src/styles/config.scss';

.container {
  background-color: $white;
  border-radius: 24px;
  margin: 10px auto;
  padding: 20px;
  max-width: 1000px;
}
.data_container {
  .icon_drag {
    display: none;
    cursor: grab;
    padding: 10px;
  }
  .logo_image_container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}
.actions_container {
  display: flex;
  align-items: center;
  justify-content: center;
  .action {
    margin: 15px;
    border-radius: 50%;
  }
}

@media (min-width: 768px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .actions_container {
    justify-content: space-between;
  }
  .data_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .icon_drag {
      display: block;
      cursor: grab;
      padding: 10px;
    }
  }
}
