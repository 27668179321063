@import 'src/styles/config.scss';

.card {
  padding: 20px;
  border-radius: 24px;
  min-height: 100px;
  position: relative;
  color: $black;
}

.primary,
.success {
  background-color: $white;
  border: 1px solid $grey;
}
.textSuccess {
  width: 70%;
}
.solid {
  background-color: $primary;
  color: $black;
}

.infoCardDecoration {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 45px;
  height: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: $primary;
  align-items: flex-start;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    z-index: 2;
    margin-top: 28px;
    background-color: $white;
    border-radius: 50%;
  }
}

.icon {
  svg {
    width: 60px;
    height: 60px;
  }
}

.info {
  background-color: $white;
  color: $primary;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  box-shadow: 5px 7px 5px $grey;
  max-width: 250px;
  padding-left: 55px;

  .infoCardDecoration {
    display: flex;
  }
}
.secondary {
  border: 1px solid $grey;
  padding: 20px 20px 40px 20px;
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    width: calc(100% + 2px);
    height: 30px;
    background-color: $grey;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
