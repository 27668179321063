@import 'src/styles/config.scss';

.container {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  margin-top: 10px;
}

.shifts_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shifts_data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shifts_number {
  font-weight: 900;
  color: $primary;
  font-size: 4rem;
  padding: 10px;
  p {
    margin-bottom: 0;
  }
}
.shifts_text {
  padding: 10px;
  text-transform: uppercase;
  .shifts_text_title {
    font-size: 1.5rem;
    color: $primary;
    margin-bottom: 0;
    font-weight: 900;
  }
  .shifts_text_subtitle {
    color: $primary;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
  }
}

.shifts_state_item_container {
  border: 1px solid $grey;
  border-radius: 10px;
  height: 50px;
  .shifts_state_colorBox {
    height: 100%;
    width: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .shifts_state_type,
  .shifts_state_amount {
    text-transform: uppercase;
    font-weight: 600;
    p {
      margin-bottom: 0;
    }
  }
  .shifts_state_type {
    flex: 1;
    margin-left: 10px;
  }
  .shifts_state_amount {
    height: 90%;
    width: 60px;
    padding: 10px;
    font-size: 1.5rem;
    border-left: 2px solid $grey;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 340px;
  }
}
