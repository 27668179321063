@import 'src/styles/config.scss';

.container {
  height: 140px;
  background-color: $primary;
}

.mobile_container {
  background-color: $primary;
  overflow: initial;
}

.name {
  color: #f3f5f9;
  font-size: 1rem;
}
.mobile_logo_container {
  background-color: $primary;
  height: 100%;
}
.logo_container {
  background-color: $primary;
  height: 100%;
}

.logo {
  height: 70%;
  width: 130px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    fill: $white2;
  }
}

.mobile_logo {
  width: 200px;
  & svg {
    fill: $white2;
  }
}

.userInfo_container {
  background-color: $primary;
  height: 100%;
  justify-content: flex-end;
}

.menu_burger_container {
  width: 20%;
  background-color: $primary;
  height: 100%;
}

.userContainer {
  background-color: #22d29d;
  flex: 1;
  height: 80px;
}

.search_container {
  background-color: $grey;
  height: 60px;
  width: 25%;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clinicList_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .info_container {
    flex: 1;
    background-color: $primary;
  }
  .clinicList_item {
    flex-direction: row;
    align-items: center;
  }
}
