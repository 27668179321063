@import 'src/styles/config.scss';

.user_container {
  background-color: $white;
  padding: 20px;
  border-radius: 24px;
  margin-bottom: 10px;
  position: relative;
  .name {
    color: $primary;
    font-size: calc(100vw * 0.04);
  }

  .place {
    color: $primary;
    font-size: calc(100vw * 0.04);
    font-weight: 600;
    text-transform: uppercase;
  }
}

@media (min-width: 768px) {
  .user_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    border-radius: 0;
    margin-bottom: 0;
    background-color: #ffffff;
    flex: 1;
    height: 90px;
    .name {
      color: $primary;
      font-size: 1rem;
    }
  }
}
