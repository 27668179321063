@import 'src/styles/config.scss';

.card {
  max-width: 100%;
  flex-direction: column;
  flex: 1;
}

.actions_container {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

}

.actions {
  margin: 4px;
  gap: 8px;
  i {
    font-size: 16px;
    line-height: 16px;
  }
}

.tab_item {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  font-size: 16px;
}

.tab_item_active {
  border-color: $primary;
  font-weight: 600;
}

.table_container {
  display: flex;
  flex-direction: column;
  tbody tr {
    border-bottom: 1px solid $primary;
  }
}

.infiniteScroll {
  gap: 16px;
}

.alert {
  align-items: center;
  display: flex;
  gap: 16px;
  a {
    text-decoration: none !important;
    text-transform: none;
  }
}

.alert_icon {
  font-size: 30px;
  line-height: 24px;
}