@import 'src/styles/config.scss';

.fullscreen_menu_container {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
}

.container {
  position: absolute;
  z-index: 100;
  background-color: $grey;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  padding: 20px;
  flex: 1;
  box-shadow: -18px 0px 0px -3px rgba(0, 0, 0, 0.04);
  -webkit-box-shadow: -18px 0px 0px -3px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: -18px 0px 0px -3px rgba(0, 0, 0, 0.04);
}

.nav_container {
  background-color: $white;
  padding: 20px;
  border-radius: 24px;
  flex: 1;
}

.animate_menu_in {
  right: 0;
  background-color: rgba(56, 82, 167, 0.5);
  transition: right 0.3s;
}

.animate_menu_out {
  right: -3000px;
  background-color: transparent;
  visibility: hidden;
  transition: right 0.3s, visibility 0.3s;
}
