@import 'src/styles/config.scss';

.container {
  width: 100%;
  -webkit-box-shadow: 4px 4px 3px -1px rgba(218, 218, 218, 0.7);
  -moz-box-shadow: 4px 4px 3px -1px rgba(218, 218, 218, 0.7);
  box-shadow: 4px 4px 3px -1px rgba(218, 218, 218, 0.7);
}

.text {
  margin: 0;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
}
