.modal_container {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 16px;

  h4 {
    margin-top: 8px;
  }
  a {
    text-decoration: underline;
  }
}

.modal_icon {
  font-size: 30px;
}

.modal_telephone {
  font-size: 25px;
  margin-top: 16px;
}

.modal_verification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.text {
  font-weight: 500;
  cursor: pointer;
}

.info_icon {
  margin-right: 8px;
  font-size: 14px;
  cursor: pointer;
  vertical-align: bottom;
}