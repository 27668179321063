@import 'src/styles/config.scss';

.mobile_container {
  position: absolute;
  right: 10px;
  bottom: 100px;
  z-index: 99;
}
.desktop_container {
  position: absolute;
  right: -140px;
  top: 300px;
  z-index: 99;
}

.btn_content {
  display: flex;
  align-items: center;
}
.mobile_btn_content {
  flex-direction: row;
  justify-content: space-between;
  min-width: 130px;
}

.desktop_btn_content {
  flex-direction: row-reverse;
  justify-content: left;
  min-width: 150px;
}

.mobile_btn_text_content {
  max-width: 100px;
  text-align: left;
  font-weight: 500;
  line-height: 12px;
  margin: 0;
}

.button_icon_container {
  width: 20px;
  height: 20px;
  svg {
    width: 100%;
    height: 100%;
    fill: $white;
  }
}

.desktop_button_icon {
  margin-right: 10px;
}
