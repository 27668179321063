@import 'src/styles/config.scss';

.main_font {
  font-family: $main-font;
}

.container {
  width: 100%;
}

.weekday {
  text-transform: uppercase;

  p,
  div {
    font-family: $main-font !important;
  }
  p {
    color: $darkGrey !important;
    font-weight: 600 !important;
  }
}

.today {
  div,
  p {
    color: $primary !important;
  }
}

.date_nav_root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 250px;
  height: 40px;
  background-color: $primary;
  border-radius: 24px;
}

.date_nav_text_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}

.date_nav_text {
  color: $white;
  margin: 0;
  font-size: 12px;
}

.nav_back {
  position: absolute;
  left: 0;
}

.nav_forward {
  position: absolute;
  right: 0;
}

.custom_toolbar_root {
  background-color: $white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  height: 100px;
}

.weekview_layout,
.dayview_layout {
  background-color: $white;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.appointment_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment_tooltip_notes_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  font-family: $main-font;
}
.radius {
  border-radius: 24px !important;
}
.simpleField,
.textarea {
  font-size: 12px;
  margin: 4px 0;
  padding: 12px 12px 12px 25px;
  border: 1px solid $primary;
  outline: none;
  background-color: $white;
  border-radius: 24px;
  width: 100%;
}

.textarea {
  min-height: 100px;
}

.date_editor {
  div {
    border-radius: 24px !important;
    height: 44px;
    font-family: $main-font;
    font-size: 12px;
    border: none;
    div {
      fieldset {
        border-color: $primary !important;
      }
    }
  }
}

.confirmation_dialog {
  div {
    border-radius: 24px !important;
  }
}
