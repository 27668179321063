@import 'src/styles/config.scss';

.table_container {
  width: 100%;
  margin: 10px auto;
  background-color: $white;
  border-radius: 13px;
  letter-spacing: 1.1px;
  padding: 15px;
  overflow: auto;
  .table {
    margin: 0 auto;
  }

  .table_head {
    border-bottom: 2px $primary solid;
  }
  .table_head_th {
    text-align: center;
    color: $primary;
    font-size: 14px;
    padding: 5px;
  }

  tbody tr {
    border-bottom: 1px solid $primary;
  }
}

.table {
  width: 100%;
}

.table {
  width: 100%;
}
.image {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
