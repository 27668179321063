@import 'src/styles/config/variables';

.container {
  outline: 1px solid #ebedf2;
  max-width: 720px;
  margin: 0 auto;
  cursor: pointer;
}

.noteText {
  font-size: $font_xs;
  opacity: 0.8;
}

.inactive {
  & :hover {
    background-color: #ebedf2;
  }
}

.active {
  background-color: $violet;
  color: #ffffff;
}

.inputSimbol {
  padding-bottom: 12px;
}

.inputContainer {
  padding-left: 10px;
}
