.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border-radius: 50%;
}
.react-calendar__navigation button:disabled {
  text-transform: uppercase;
  color: #2d2d2d;
  font-weight: 500;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75em;
  background-color: #dedfde;
  border-right: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
}

abbr,
abbr[title] {
  text-decoration: none;
  text-transform: uppercase;
  -webkit-text-decoration: none;
}
abbr[title] {
  text-decoration: none;
  text-transform: uppercase;
  -webkit-text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  width: 45.14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ededed;
  border-top: 1px solid #ededed;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days {
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.react-calendar__month-view__days__day--weekend {
  color: #1cac81;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  padding: 6px;
  background: none;
  text-align: center;
  width: 45.14px;
  height: 55px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-left: 1px solid #ededed;
  border-top: 1px solid #ededed;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #1cac81;
  color: #ffffff;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #1cac81;
}
.react-calendar__tile--hasActive {
  background: #1cac81;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #d8e4ee;
  color: #1cac81;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #d8e4ee;
  color: #1cac81;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@media (max-width: 767px) {
  .react-calendar {
    background-color: #1cac81;
    border-radius: 24px;
    padding: 30px;
  }
  .react-calendar__month-view__weekdays {
    background-color: #1cac81;
    color: #fafafa;
    border: none;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .react-calendar__month-view__weekdays__weekday {
    border: none;
  }
  .react-calendar__month-view__days {
    border: none;
  }
  .react-calendar__tile {
    align-items: center;
    justify-content: center;
    border: none;
    color: #fafafa;
    border-radius: 50%;
    width: 47px;
    height: 47px;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #fafafa;
    color: #1cac81;
  }
  .react-calendar__tile--now {
    background-color: #4e97de;
    color: #fafafa;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #fafafa;
  }
}
