@import '../../styles/config.scss';

.success {
  color: $primary;
}

.danger {
  color: $danger;
}

.warning {
  color: $warning;
}

.info {
  color: $info;
}

.overflowXHidden {
  overflow-x: hidden;
}
