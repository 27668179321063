@import 'src/styles/config.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  -webkit-box-shadow: 4px 4px 3px -1px rgba(218, 218, 218, 0.7);
  -moz-box-shadow: 4px 4px 3px -1px rgba(218, 218, 218, 0.7);
  box-shadow: 4px 4px 3px -1px rgba(218, 218, 218, 0.7);
}

.text {
  margin: 0;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
}

.actions_container {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

}

.actions {
  margin: 4px;
  gap: 8px;
  i {
    font-size: 16px;
    line-height: 16px;
  }
}

.read_more {
  color: blue;
  display: inline-block;
}