@import 'src/styles/config.scss';

.loginBg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
  }
}

.medic {
  background-color: $medic !important;
}
.patient {
  background-color: $patient !important;
}
.clinic {
  background-color: $clinic !important;
}
