@import 'src/styles/config.scss';

.nav {
  color: $black;
  height: 50px;

  .menu {
    margin: 0;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
    height: 100%;
  }

  .mobile_menu {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .menuItem {
    height: 100%;
    width: fit-content;
    font-size: 12px;
  }

  .mobile_menuItem {
    font-size: calc(100vw * 0.03);
    color: $darkGrey2;
  }

  a {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    font-weight: 600;
    text-transform: uppercase;
    height: 100%;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-right: 6px;
    }

    &.stethoscope::before {
      background-image: url('./fi-rs-stethoscope.svg');
    }

    &.calendar::before {
      background-image: url('./fi-rs-calendar.svg');
    }

    &.address-book::before {
      background-image: url('./fi-rs-address-book.svg');
    }

    &.document::before {
      background-image: url('./fi-rs-document.svg');
    }

    &.add-document::before {
      background-image: url('./fi-rs-add-document.svg');
    }
    &.message::before {
      background-image: url('./message-circle.svg');
    }

    &.active {
      color: $primary;
      // border-bottom: 3px solid $primary;

      /* This filter changes the color of the icon when the NavLink is active */
      &::before {
        filter: invert(27%) sepia(86%) saturate(580%) hue-rotate(192deg)
          brightness(98%) contrast(97%);
      }
    }
  }
}
