@import 'src/styles/config.scss';

.alert {
  padding: 1rem;
  position: relative;
  color: $white;
  border-color: rgba(210, 215, 224, 1);
  border-width: 1px;
  border-style: solid;
  border-radius: 0.375rem;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  & svg {
    width: 100%;
    height: 100%;
  }
}

.alert_close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  & svg {
    width: 70%;
    height: 70%;
    fill: $white;
  }
}
.alert_content {
  width: 80%;
  a {
    text-decoration: underline;
    &:hover {
      color: $white;
    }
  }
}

.danger {
  background-color: $danger;
}
.warning {
  background-color: $warning;
}
.primary {
  background-color: $primary;
}
.success {
  background-color: $success;
}
.message {
  background-color: $message;
}
